.spinner {
  width: 60px;
  height: 60px;
  position: relative;
  /* margin: 100px auto; */
  z-index: -1;
}
.spinner.spinner1 {
  width: 3vw !important;
  height: 3vw !important;
}

.spinner.spinner2 {
  width: 4vw !important;
  height: 4vw !important;
}

.spinner.spinner3 {
  width: 9vw !important;
  height: 9vw !important;
}

.spinner.spinner4 {
  width: 3vw !important;
  height: 3vw !important;
}

.spinner.spinner5 {
  width: 6vw !important;
  height: 6vw !important;
}

.spinner.spinner6 {
  width: 9vw !important;
  height: 9vw !important;
}

.double-bounce1 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #176ca8;
  position: absolute;

  -webkit-animation: sk-bounce 5s infinite ease-in-out;
  animation: sk-bounce 5s infinite ease-in-out;
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

body {
  overflow-x: hidden;
}

/* button 1*/

.primary-button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.primary-button {
  --primary-color: #111;
  --hovered-color: #176ca8;
  position: relative;
  display: flex;
  font-weight: 500;
  font-size: 20px;
  gap: 0.5rem;
  align-items: center;
}

.primary-button p {
  margin: 0;
  position: relative;
  font-size: 16px;
  color: var(--primary-color);
}

.primary-button:hover p {
  color: var(--hovered-color);
}

.primary-button::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.primary-button p::before {
  position: absolute;
  /* box-sizing: border-box; */
  /* content: "Learn More"; */
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.primary-button:hover::after {
  width: 105%;
}

.primary-button:hover p::before {
  width: 100%;
}

.primary-button:hover svg {
  transform: translateX(4px);
  color: var(--hovered-color);
  font-weight: 800;
}

.primary-button svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
}

/* button 2*/

.secondary-button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.secondary-button {
  --secondary-color: white;
  --hovered-color: white;
  position: relative;
  display: flex;
  font-weight: 500;
  font-size: 20px;
  gap: 0.5rem;
  align-items: center;
}

.secondary-button p {
  margin: 0;
  position: relative;
  font-size: 16px;
  color: var(--secondary-color);
}

.secondary-button:hover p {
  color: var(--hovered-color);
}

.secondary-button::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.secondary-button p::before {
  position: absolute;
  /* box-sizing: border-box; */
  /* content: "Learn More"; */
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.secondary-button:hover::after {
  width: 105%;
}

.secondary-button:hover p::before {
  width: 100%;
}

.secondary-button:hover svg {
  transform: translateX(4px);
  color: var(--hovered-color);
  font-weight: 800;
}

.secondary-button svg {
  color: var(--secondary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
}

.clients-grid-cols {
  grid-template-columns: 30vw auto;
}

@media only screen and (max-width: 768px) {
  .clients-grid-cols {
    grid-template-columns: auto;
  }
}

/* carousel */

.carousel_SD .slick-slide.slick-active.slick-current {
  padding: 0 10vw;
  /* max-width: 80vw; */
}

.circle-hardware {
  display: inline-block;
  background: #176ca8;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  /* border: 2px solid #111; */
  /* box-shadow: 0 0 0 0.5rem #fff; */
}

.circle-software {
  display: inline-block;
  background: #e2e6e9;
  background: #e2e6e9;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  /* border: 2px solid #111; */
  /* box-shadow: 0 0 0 0.5rem #fff; */
}

.container1,
.container2 {
  height: 3rem;
  margin-bottom: 80px;
}

@media (min-width: 768px) {
  .circle-hardware,
  .circle-software {
    width: 11vh;
    height: 11vh;
  }

  .container1,
  .container2 {
    height: 11vh;
    margin-bottom: 13vh;
  }
}

.container1:before {
  content: "";
  position: absolute;
  border-bottom: 4px #176ca8 solid;
  height: 200px;
  width: 80vw;
  z-index: 1;
  transform: translateY(-50%);
}

.container2:before {
  content: "";
  position: absolute;
  border-bottom: 4px white solid;
  height: 200px;
  width: 80vw;
  z-index: 1;
  transform: translateY(-50%);
}

/* contact css */

div {
  margin: auto;
}

.semi-circle {
  @apply grid place-content-center;
  height: 200px;
  width: 200vh;
  border-radius: 50% 50% 0 0/100% 100% 0 0;
  background-color: #dbdbdb;
  z-index: -1;
}

.parent-circle {
  @apply -z-[1] relative grid place-content-center;
}

/* works */
.slider-works {
  width: 100vw;
}
.slider-works .slick-slider {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0 20px; */
}
.slider-works .slick-list {
  position: relative;
  display: block !important;
  overflow: hidden;
  padding: 25vh 100px !important;
  width: 100%;
}
.slider-works .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
}
.slider-works .slick-slide div {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slider-works .slick-slide .carousel-img {
  object-fit: cover;
  border-radius: 2px;
  min-width: 150px !important;
  width: 80%;
}
.slider-works .slick-slide div div span {
  margin-top: 10px;
  width: inherit;
  text-align: center;
  font-size: 0.75rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #111;
}
@media screen and (max-width: 580px) {
  .slider-works .slick-slide div div span {
    display: none;
  }
  .slider-works .slick-slide.slick-current div div span {
    display: block;
    margin-top: 10px;
    width: inherit;
    text-align: center;
    font-size: 0.7rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: #111;
  }
}
.slider-works .slick-slide:not(.slick-active) {
  @apply opacity-[40%];
  position: relative;
  z-index: 997;
}
.slider-works .slick-slide.slick-active:not(.slick-current) {
  @apply opacity-[50%];
  position: relative;
  z-index: 998;
  transform: scale(1.3);
  transition: 0.25s;
}
.slider-works .slick-slide.slick-active.slick-current {
  position: relative;
  z-index: 999;
  transform: scale(1.8);
  transition: 0.25s;
}
.slider-works .slick-slide.slick-active:not(.slick-current) .carousel-img,
.slider-works .slick-slide.slick-active.slick-current .carousel-img,
.slider-works .slick-slide:not(.slick-active) .carousel-img {
  width: 100% !important;
}
.slider-works .slick-prev,
.slider-works .slick-next {
  display: none !important;
}
.slider-works .custom-prevArrow,
.slider-works .custom-nextArrow {
  position: relative;
  z-index: 99999;
  top: -10px;
}
.slider-works .custom-prevArrow {
  left: -10px;
}
.slider-works .custom-nextArrow {
  right: -10px;
}
.slider-works .custom-prevArrow:hover,
.slider-works .custom-nextArrow:hover {
  fill: red;
  cursor: pointer;
}

/* thing */

.slider-works .carousel-img {
  @apply w-full h-full object-cover;
}

@media only screen and (max-width: 600px) {
  .slider-works .carousel-div {
    @apply rounded-full;
    width: 55vw !important;
    height: 55vw !important;
  }
  .slider-works .slick-list {
    padding: 5vh 100px 0vh 100px !important;
  }
}

.slider-works .carousel-div {
  @apply bg-[#176CA8] rounded-t-full mx-auto;
  width: 25vw !important;
  height: 25vw !important;
}

.slider-works .slick-center .carousel-div {
  @apply opacity-[100%];
  /* width: 40vw !important; */
  /* height: 40vw !important; */
}

.slider-works .carousel-img {
  @apply rounded-full w-full h-full invisible;
}

.slider-works .slick-center .carousel-div .carousel-img {
  @apply visible rounded-t-full object-cover;
}
