@keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 80%;
  }
  50% {
    opacity: 0;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 29%;
  }
}
.scroll-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}
.scroll-btn > * {
  font-size: 12px;
  font-weight: normal;
  color: #000000;
  letter-spacing: 2px;
}

.scroll-btn .mouse {
  position: relative;
  display: block;
  width: 25px;
  height: 40px;
  margin: 0 auto 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid #000000;
  border-radius: 23px;
}
.scroll-btn .mouse > * {
  position: absolute;
  display: block;
  top: 29%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  background: black;
  border-radius: 50%;
  -webkit-animation: ani-mouse 2.5s linear infinite;
  -moz-animation: ani-mouse 2.5s linear infinite;
  animation: ani-mouse 2.5s linear infinite;
}
