html {
  height: 100%;
  background-color: #f5f5f5;
  overflow-x: hidden;
}
body {
  background: transparent;
}
.lamp {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  background: radial-gradient(ellipse at bottom, #dff2ff, rgb(236, 236, 236));

  height: 100vh;
  width: 180vw;
  min-width: 100vw;
  z-index: -1;
}

.lava {
  filter: url("#goo");
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.blob {
  border-radius: 50%;
  background: #176ca8;
  position: absolute;
}

.blob.bottom {
  border-radius: 50%;
  width: 100%;
  height: 4%;
  bottom: -3%;
  left: 0;
}

.blob:nth-child(1) {
  width: 5vw;
  height: 5vw;
  right: 35%;
  bottom: -15%;

  animation: wobble 4s ease-in-out alternate infinite,
    blob-one ease-in-out 13s infinite;
}
.blob:nth-child(2) {
  width: 10vw;
  height: 10vw;
  right: 24%;
  bottom: -65%;

  animation: wobble 5s ease-in-out alternate infinite,
    blob-two ease-in-out 22s infinite;
}
.blob:nth-child(3) {
  width: 8vw;
  height: 8vw;
  bottom: -15%;
  left: 34%;

  animation: wobble 6s ease-in-out alternate infinite,
    blob-three ease-in-out 16s infinite;
}
.blob:nth-child(4) {
  width: 6.5vw;
  height: 6.5vw;
  bottom: -20%;
  left: 30%;
  animation: wobble 8s ease-in-out alternate infinite,
    blob-four ease-in-out 12s infinite;
}
.blob:nth-child(5) {
  width: 3vw;
  height: 3vw;
  bottom: -25%;
  left: 45%;

  animation: wobble 9s ease-in-out alternate infinite,
    blob-five ease-in-out 32s infinite;
}
.blob:nth-child(6) {
  width: 2.5vw;
  height: 2.5vw;
  bottom: -25%;
  right: 34%;

  animation: wobble 10s ease-in-out alternate infinite,
    blob-six ease-in-out 12s infinite;
}
.blob:nth-child(7) {
  width: 12vw;
  height: 12vw;
  bottom: -85%;
  right: 40%;

  animation: wobble 11s ease-in-out alternate infinite,
    blob-seven ease-in-out 32s infinite;
}

/* Bubbles for mobile*/
@media (max-width: 768px) {
  .blob:nth-child(1) {
    width: 50px;
    height: 50px;
  }
  .blob:nth-child(2) {
    width: 100px;
    height: 100px;
  }
  .blob:nth-child(3) {
    width: 80px;
    height: 80px;
  }
  .blob:nth-child(4) {
    width: 60px;
    height: 60px;
  }
  .blob:nth-child(5) {
    width: 30px;
    height: 30px;
  }
  .blob:nth-child(6) {
    width: 50px;
    height: 50px;
  }
  .blob:nth-child(7) {
    width: 120px;
    height: 120px;
  }
}

@keyframes blob-one {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-700%);
  }
}
@keyframes blob-two {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-420%);
  }
}
@keyframes blob-three {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-305%);
  }
}
@keyframes blob-four {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-605%);
  }
}
@keyframes blob-five {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-700%);
  }
}
@keyframes blob-six {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-700%);
  }
}
@keyframes blob-seven {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-300%);
  }
}

@keyframes wobble {
  50% {
    /* border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%; */
    scale: 0.8;
  }
  100% {
    /* border-radius: 38% 52% 75% 36% / 50% 40% 50% 60%; */
    scale: 1.1;
  }
}

/* waves */

.waves {
  /* position: relative; */
  width: 100%;
  height: 15vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 60px;
    min-height: 60px;
  }
}
